import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
// import classic from "./../pics/products_classic.jpg";
// import { useUserSettings } from "../user";
// import { i18n } from "../i18n";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";

export const FindUs = () => {
  // const [settings] = useUserSettings();
  // const { language } = settings;
  const position = { lat: -34.8756469, lng: -56.078177 };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const locations = [
    { key: "devoto 1", location: { lat: -34.894719, lng: -56.107513 } },
    {
      key: "devoto 4",
      location: { lat: -34.892987422644836, lng: -56.066432419648024 },
    },
    {
      key: "devoto 6",
      location: { lat: -34.877821535556635, lng: -56.057322380836496 },
    },
    {
      key: "devoto 9",
      location: { lat: -34.93285087928557, lng: -54.93959253235924 },
    },
    {
      key: "devoto 10",
      location: { lat: -34.88056913924843, lng: -56.08040791701939 },
    },
    {
      key: "devoto 11",
      location: { lat: -34.83531256676122, lng: -56.2110845520456 },
    },
    {
      key: "devoto 14",
      location: { lat: -34.86537960451683, lng: -55.26938140352654 },
    },
    {
      key: "devoto 13",
      location: { lat: -34.85571008471274, lng: -55.2914766323624 },
    },
    {
      key: "devoto 15",
      location: { lat: -34.86518537586531, lng: -56.209914170439454 },
    },
    {
      key: "devoto 19",
      location: { lat: -34.86428895621799, lng: -56.195464250777064 },
    },
    {
      key: "devoto 22",
      location: { lat: -34.90764761371652, lng: -56.145693913321516 },
    },
    {
      key: "devoto 23",
      location: { lat: -34.91922060248528, lng: -56.15752997593635 },
    },
    {
      key: "disco 1",
      location: { lat: -34.91524557814482, lng: -56.155595139106715 },
    },
    {
      key: "disco 2",
      location: { lat: -34.875984182568025, lng: -56.19815723725983 },
    },
    {
      key: "disco 4",
      location: { lat: -34.88954107225841, lng: -56.098640891865315 },
    },
    {
      key: "disco 5",
      location: { lat: -34.943105647768405, lng: -54.932594015168426 },
    },
    {
      key: "disco 7",
      location: { lat: -34.90564366389269, lng: -56.15472624955082 },
    },
    {
      key: "disco 8",
      location: { lat: -34.913279422500516, lng: -54.96140547468896 },
    },
    {
      key: "disco 9",
      location: { lat: -34.92490415798843, lng: -56.157582430511134 },
    },
    {
      key: "disco 11",
      location: { lat: -34.88952326873515, lng: -56.160948067943245 },
    },
    {
      key: "disco 13",
      location: { lat: -34.909011003431004, lng: -56.144437745853715 },
    },
    {
      key: "disco 14",
      location: { lat: -34.96610903732147, lng: -54.94657278023212 },
    },
    {
      key: "disco 18",
      location: { lat: -34.826068365156985, lng: -55.96506600167981 },
    },
    {
      key: "disco 19",
      location: { lat: -34.77119899478617, lng: -55.76101103051749 },
    },
    {
      key: "disco 20",
      location: { lat: -34.900186693312136, lng: -56.162977199736 },
    },
    {
      key: "disco 23",
      location: { lat: -34.80546350739354, lng: -55.92767408165328 },
    },
    {
      key: "disco 27",
      location: { lat: -34.88748597285385, lng: -56.140886815725345 },
    },
    {
      key: "la cabaña disco el pinar",
      location: { lat: -34.80423751814455, lng: -55.90819433234415 },
    },
    {
      key: "geant 1",
      location: { lat: -34.86527542210827, lng: -56.02410095934897 },
    },
    {
      key: "geant 2",
      location: { lat: -34.86812543264055, lng: -56.170137532361906 },
    },
    {
      key: "macro san martin",
      location: { lat: -34.866606296226706, lng: -56.1801254188686 },
    },
    {
      key: "macro carrasco",
      location: { lat: -34.86937300928329, lng: -56.022262030513296 },
    },
    {
      key: "macro punta",
      location: { lat: -34.91566371868324, lng: -54.94561103420831 },
    },
    {
      key: "la molienda 18",
      location: { lat: -34.90626920380636, lng: -56.1974635900312 },
    },
    {
      key: "la molienda ejido",
      location: { lat: -34.904002904350506, lng: -56.187110445854024 },
    },
    {
      key: "la molienda sarandi",
      location: { lat: -34.90710950126832, lng: -56.20393944770223 },
    },
    {
      key: "la molienda punta carretas",
      location: { lat: -34.9235966446564, lng: -56.15943820352414 },
    },
    {
      key: "la molienda costa urbana",
      location: { lat: -34.84084998327439, lng: -55.9933489881856 },
    },
    {
      key: "el granero",
      location: { lat: -34.87413873418057, lng: -56.15408340352621 },
    },
    {
      key: "tienda nuevo origen",
      location: { lat: -34.884001344583574, lng: -56.1676991035258 },
    },
    {
      key: "alemán",
      location: { lat: -34.90023678727947, lng: -54.952623088183124 },
    },
    {
      key: "alemán lussich",
      location: { lat: -34.89620738180312, lng: -54.97070760352515 },
    },
    {
      key: "tito",
      location: { lat: -34.911528234071525, lng: -54.953147217018014 },
    },
    {
      key: "menos mal",
      location: { lat: -34.90928660917835, lng: -54.86498481701816 },
    },

    {
      key: "gramelia",
      location: { lat: -34.52637276082051, lng: -56.27937380354048 },
    },
    {
      key: "autoservice lo de vivi",
      location: { lat: -34.8349729234809, lng: -54.64459098818575 },
    },
    {
      key: "bodegon criollo",
      location: { lat: -34.90740171572647, lng: -56.17375573236025 },
    },
  ];

  const PoiMarkers = (props) => {
    return (
      <>
        {props.pois.map((poi) => (
          <AdvancedMarker key={poi.key} position={poi.location}>
            <Pin
              background={"#ff8800"}
              glyphColor={"#fff"}
              borderColor={"#fff"}
            />
          </AdvancedMarker>
        ))}
      </>
    );
  };

  const MobileView = () => {
    return (
      <APIProvider
        apiKey={"AIzaSyBzqd5jIPIaCjn-1RwM3LL5-rSe0YWyRjo"}
        onLoad={() => console.log("bazinga")}
      >
        <div className="find_page_m">
          <Map defaultCenter={position} defaultZoom={9} mapId="DEMO_MAP_ID">
            <AdvancedMarker position={position}>
              <PoiMarkers pois={locations} />
            </AdvancedMarker>
          </Map>
          {/* <text>{i18n({ index: "find_us", language })}</text> */}
        </div>
      </APIProvider>
    );
  };

  const DesktopView = () => {
    return (
      <APIProvider
        apiKey={"AIzaSyBzqd5jIPIaCjn-1RwM3LL5-rSe0YWyRjo"}
        onLoad={() => console.log("bazinga")}
      >
        <div className="find_page">
          <Map defaultCenter={position} defaultZoom={9} mapId="DEMO_MAP_ID">
            <AdvancedMarker position={position}>
              <PoiMarkers pois={locations} />
            </AdvancedMarker>
          </Map>
          {/* <text>{i18n({ index: "find_us", language })}</text> */}
        </div>
      </APIProvider>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
