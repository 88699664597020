import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useUserSettings } from "../user";
import { i18n } from "../i18n";
import all from "./../pics/Halah0100_web.jpg";
import all_m from "./../pics/crop_all.jpg";
import classic from "./../pics/Halah0055_web.jpg";
import plateado from "./../pics/Halah0067_web.jpg";
import paprika from "./../pics/Halah0064_web.jpg";
import pesto from "./../pics/Halah0069_web.jpg";
import cacao from "./../pics/Halah0133_web.jpg";
import sweet from "./../pics/Halah0136_web.jpg";
import sesame from "./../pics/Halah0145_web.jpg";
import big from "./../pics/Halah0081_web.jpg";
// import gluten from "./../pics/sugar.png";
import { AsyncImage } from "loadable-image";
import { Slide } from "transitions-kit";
import {
  Plateado,
  Classic,
  Paprika,
  Pesto,
  Cacao,
  Sweet,
  Big,
  Sesame,
} from "./ProductsMobile";
import { DelayedText } from "./DelayedText";

export const Products = () => {
  const [settings] = useUserSettings();
  const { language } = settings;
  const styled = { width: "100%", height: "100vh", marginTop: 100 };
  const styledd = { width: "100%", height: 300 };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <div className="product_page_m">
        <AsyncImage
          style={styledd}
          src={all_m}
          Transition={(props) => <Slide direction="left" {...props} />}
          error={
            <div style={{ background: "#eee", height: 500, width: 500 }} />
          }
        />
        <Classic />
        <Plateado />
        <Paprika />
        <Pesto />
        <Cacao />
        <Sweet />
        <Sesame />
        <Big />
        {/* <ProductsMobile /> */}
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="product_page">
        <AsyncImage
          style={styled}
          src={all}
          Transition={(props) => <Slide direction="left" {...props} />}
          error={
            <div style={{ background: "#eee", height: 500, width: 500 }} />
          }
        />
        <div className="product_contained">
          <div className="product">
            <img className="product_img" src={plateado} alt=""></img>
            <div className="content">
              <text className="arttitle">
                {i18n({ index: "silver_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "silver_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "silver_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "silver_nutrition", language })}
              </text>
              {/* <div className="info_nutricional">
                <img className="nut_info" src={gluten} alt=""></img>
                <img className="nut_info" src={gluten} alt=""></img>
                <img className="nut_info" src={gluten} alt=""></img>
              </div> */}
            </div>
          </div>

          <div className="product">
            <img className="product_img" src={classic} alt=""></img>
            <div class="content">
              <text className="arttitle">
                {i18n({ index: "classic_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "classic_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "classic_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "classic_nutrition", language })}
              </text>
            </div>
          </div>

          {/* <div className="extraspace">
            <div>
              <text class="big_text">
                {i18n({ index: "classic_1", language })}
              </text>
              <text class="big_text">
                {i18n({ index: "classic_2", language })}
              </text>
              <text class="big_text">
                {i18n({ index: "classic_3", language })}
              </text>
            </div>
          </div> */}
          <DelayedText />
        </div>

        <div className="product_contained">
          <div className="extraspace">
            <div>
              <text class="big_text_orange">
                {i18n({ index: "flavor_1", language })}
              </text>
              <text class="big_text_orange">
                {i18n({ index: "flavor_2", language })}
              </text>
              <text class="big_text_orange">
                {i18n({ index: "flavor_3", language })}
              </text>
            </div>
          </div>
          <div className="product">
            <img className="product_img" src={paprika} alt=""></img>
            <div class="content">
              <text className="arttitle">
                {i18n({ index: "paprika_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "paprika_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "paprika_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "paprika_nutrition", language })}
              </text>
            </div>
          </div>
          <div className="product">
            <img className="product_img" src={pesto} alt=""></img>
            <div class="content">
              <text className="arttitle">
                {i18n({ index: "pesto_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "pesto_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "pesto_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "pesto_nutrition", language })}
              </text>
            </div>
          </div>
        </div>

        <div className="product_contained">
          <div className="product">
            <img className="product_img" src={sweet} alt=""></img>
            <div class="content">
              <text className="arttitle">
                {i18n({ index: "sweet_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "sweet_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "sweet_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "sweet_nutrition", language })}
              </text>
            </div>
          </div>

          <div className="product">
            <img className="product_img" src={cacao} alt=""></img>
            <div class="content">
              <text className="arttitle">
                {i18n({ index: "cacao_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "cacao_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "cacao_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "cacao_nutrition", language })}
              </text>
            </div>
          </div>

          <div className="extraspace">
            <div>
              <text class="big_text_orange">
                {i18n({ index: "sweet", language })}
              </text>
            </div>
          </div>
        </div>

        <div className="product_contained">
          <div className="extraspace">
            <div>
              <text class="big_text">
                {i18n({ index: "variety_1", language })}
              </text>
              <text class="big_text">
                {i18n({ index: "variety_2", language })}
              </text>
              <text class="big_text">
                {i18n({ index: "variety_3", language })}
              </text>
            </div>
          </div>
          <div className="product">
            <img className="product_img" src={sesame} alt=""></img>
            <div class="content">
              <text className="arttitle">
                {i18n({ index: "sesame_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "sesame_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "sesame_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "sesame_nutrition", language })}
              </text>
            </div>
          </div>
          <div className="product">
            <img className="product_img" src={big} alt=""></img>
            <div class="content">
              <text className="arttitle">
                {i18n({ index: "big_title", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "big_ingredients", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "big_description", language })}
              </text>
              <text className="arttext">
                {i18n({ index: "nutritional_info", language })}
              </text>
              <text className="artsubtext">
                {i18n({ index: "big_nutrition", language })}
              </text>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
