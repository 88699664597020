import macro from "./../pics/macro.png";
import disco from "./../pics/discofresh.jpg";
import devoto from "./../pics/devoto.jpg";
import geant from "./../pics/geant.png";
import betabel from "./../pics/betabel.jpeg";
import tito from "./../pics/tito.jpg";
import molienda from "./../pics/molienda.png";
import lahuella from "./../pics/lahuella.png";
import naranjo from "./../pics/naranjo.png";
import granero from "./../pics/granero.png";
import start from "./../pics/Halah0389_web.jpg";
import start_m from "./../pics/Halah0372_web.jpg";
import "./../Style.css";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import { useUserSettings } from "../user";
import { i18n } from "../i18n";
import "animate.css";
import { AsyncImage } from "loadable-image";
import { Slide, Fade } from "transitions-kit";
import { HistoryMobile, RenaMobile, TextsMobile } from "./HomeAsyncPicsMobile";
import {
  HistoryDesktop,
  MyComponent,
  RenaDesktop,
} from "./HomeAsyncPicsDesktop";

export const Home = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const styled = {
    width: "100%",
    height: "100vh",
    marginTop: 140,
    position: "absolute",
    // zIndex: 300,
  };

  const styledd = {
    width: "100%",
    height: "100vh",
    marginTop: 100,
    position: "absolute",
    // zIndex: 300,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <>
        <AsyncImage
          style={styledd}
          src={start_m}
          Transition={Fade}
          error={
            <div style={{ background: "#eee", height: 500, width: 500 }} />
          }
        />
        <div className="start_text_m">
          {i18n({ index: "start_1", language })}
        </div>
        <div className="start_text_m2">
          {i18n({ index: "start_2", language })}
        </div>
        <div className="home_v2_mobile">
          <TextsMobile />
          <RenaMobile />
        </div>

        <div className="home_clients_mobile">
          <div className="clients_text">
            <text>{i18n({ index: "clients", language })}</text>
          </div>
          <div className="clients_mobile">
            <button className="client_button_mobile">
              <a href="https://www.macromercado.com.uy/">
                <img src={macro} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://www.disco.com.uy/">
                <img src={disco} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://www.devoto.com.uy/">
                <img src={devoto} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://www.geant.com.uy/">
                <img src={geant} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://betabel.uy/">
                <img src={betabel} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://paradorlahuella.com/">
                <img src={lahuella} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://www.elnaranjo.com.uy/">
                <img src={naranjo} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://www.elgranero.com.uy/">
                <img src={granero} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://www.lamolienda.uy/">
                <img src={molienda} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button_mobile">
              <a href="https://tito.uy/">
                <img src={tito} className="client" alt="logo"></img>
              </a>
            </button>
          </div>
          <div className="clients_text_2">
            <text>{i18n({ index: "clients_2", language })}</text>
          </div>
        </div>
        <HistoryMobile />
      </>
    );
  };

  const DesktopView = () => {
    return (
      <>
        <AsyncImage
          style={styled}
          src={start}
          Transition={(props) => <Slide direction="right" {...props} />}
          error={
            <div style={{ background: "#eee", height: 500, width: 500 }} />
          }
        />
        <div className="start_text_1">
          {i18n({ index: "start_1", language })}
        </div>
        <div className="start_text_2">
          {i18n({ index: "start_2", language })}
        </div>
        <MyComponent />
        <RenaDesktop />
        <div className="home_clients">
          <div className="clients_text">
            <text>{i18n({ index: "clients", language })}</text>
          </div>
          <div className="clients">
            <button className="client_button">
              <a href="https://www.macromercado.com.uy/">
                <img src={macro} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://www.disco.com.uy/">
                <img src={disco} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://www.devoto.com.uy/">
                <img src={devoto} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://www.geant.com.uy/">
                <img src={geant} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://betabel.uy/">
                <img src={betabel} className="client" alt="logo"></img>
              </a>
            </button>
          </div>
          <div className="clients">
            <button className="client_button">
              <a href="https://www.elnaranjo.com.uy/">
                <img src={naranjo} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://paradorlahuella.com/">
                <img src={lahuella} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://www.elgranero.com.uy/">
                <img src={granero} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://www.lamolienda.uy/">
                <img src={molienda} className="client" alt="logo"></img>
              </a>
            </button>
            <button className="client_button">
              <a href="https://tito.uy/">
                <img src={tito} className="client" alt="logo"></img>
              </a>
            </button>
          </div>
          <div className="clients_text_2">
            <text>{i18n({ index: "clients_2", language })}</text>
          </div>
        </div>
        <HistoryDesktop />
      </>
    );
  };
  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
