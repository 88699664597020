import { useEffect, useRef } from "react";
import { useUserSettings } from "../user";
import { useSpring, animated } from "@react-spring/web";
import { i18n } from "../i18n";

export const DelayedText = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  useEffect(() => {
    const handleScroll = () => {
      // For the first section
      if (refText1.current) {
        const rectText1 = refText1.current.getBoundingClientRect();
        if (rectText1.top < window.innerHeight && rectText1.bottom > 0) {
          textApi1.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1]);

  return (
    <div className="extraspace">
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        //   className="home_ren_text"
      >
        <div>
          <text class="big_text">{i18n({ index: "classic_1", language })}</text>
          <text class="big_text">{i18n({ index: "classic_2", language })}</text>
          <text class="big_text">{i18n({ index: "classic_3", language })}</text>
        </div>
      </animated.div>
    </div>
  );
};
