import useOnScreen from "../refs";
import "animate.css";
import "./../Style.css";
import { Fade } from "transitions-kit";
import fortext11 from "./../pics/crop_classic.jpg";
import fortext21 from "./../pics/crop_silver.jpg";
import fortext31 from "./../pics/Halah0353_web.jpg";
import tahini3 from "./../pics/home3.jpg";
import dulce from "./../pics/dulce.jpg";
import { AsyncImage } from "loadable-image";
import React, { useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { HashLink } from "react-router-hash-link";
import EastSharpIcon from "@mui/icons-material/EastSharp";

export const TextsMobile = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();
  const refImg1 = useRef();
  const refText2 = useRef();
  const refImg2 = useRef();
  const refText3 = useRef();
  const refImg3 = useRef();
  const refText4 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings2, textApi2] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings2, imgApi2] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings3, textApi3] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings3, imgApi3] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings4, textApi4] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  useEffect(() => {
    const handleScroll = () => {
      // For the first section
      if (refText1.current) {
        const rectText1 = refText1.current.getBoundingClientRect();
        if (rectText1.top < window.innerHeight && rectText1.bottom > 0) {
          textApi1.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg1.current) {
        const rectImg1 = refImg1.current.getBoundingClientRect();
        if (rectImg1.top < window.innerHeight && rectImg1.bottom > 0) {
          imgApi1.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
        }
      }

      // For the second section
      if (refText2.current) {
        const rectText2 = refText2.current.getBoundingClientRect();
        if (rectText2.top < window.innerHeight && rectText2.bottom > 0) {
          textApi2.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg2.current) {
        const rectImg2 = refImg2.current.getBoundingClientRect();
        if (rectImg2.top < window.innerHeight && rectImg2.bottom > 0) {
          imgApi2.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
        }
      }

      // For the third section
      if (refText3.current) {
        const rectText3 = refText3.current.getBoundingClientRect();
        if (rectText3.top < window.innerHeight && rectText3.bottom > 0) {
          textApi3.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg3.current) {
        const rectImg3 = refImg3.current.getBoundingClientRect();
        if (rectImg3.top < window.innerHeight && rectImg3.bottom > 0) {
          imgApi3.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
        }
      }

      if (refText4.current) {
        const rectText4 = refText4.current.getBoundingClientRect();
        if (rectText4.top < window.innerHeight && rectText4.bottom > 0) {
          textApi4.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1, imgApi1, textApi2, imgApi2, textApi3, imgApi3, textApi4]);

  return (
    <div className="home_v2_lower_mobile">
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="home_box_mobile"
      >
        <text className="box_t">{i18n({ index: "home_q1", language })}</text>
        <text className="box_st_m">
          {i18n({ index: "home_desc_1", language })}
        </text>
      </animated.div>
      <animated.img
        ref={refImg1}
        src={fortext11}
        className="home_desc_pic_m"
        alt="1"
        style={imgSprings1}
      />

      <animated.div
        ref={refText2}
        style={{
          ...textSprings2,
        }}
        className="home_box_mobile"
      >
        <text className="box_t">{i18n({ index: "home_q2", language })}</text>
        <text className="box_st_m">
          {i18n({ index: "home_desc_2", language })}
        </text>
      </animated.div>
      <animated.img
        ref={refImg2}
        src={fortext21}
        className="home_desc_pic_m"
        alt="2"
        style={imgSprings2}
      />

      <animated.div
        ref={refText3}
        style={{
          ...textSprings3,
        }}
        className="home_box_mobile"
      >
        <text className="box_t">HALAH</text>
        <text className="box_st_m">
          {i18n({ index: "home_desc_3", language })}
        </text>
      </animated.div>
      <animated.img
        ref={refImg3}
        src={fortext31}
        className="home_desc_pic_m"
        alt="3"
        style={imgSprings3}
      />

      <animated.div
        ref={refText4}
        style={{
          ...textSprings4,
        }}
        className="home_box_mobile_2"
      >
        <HashLink className="rec_button_m" smooth to={"/recipes#noodles"}>
          <text className="box_t">{i18n({ index: "see_recs", language })}</text>
          <EastSharpIcon
            style={{ color: "#fff", fontSize: 30, marginLeft: 20 }}
          />
        </HashLink>
      </animated.div>
    </div>
  );
};

export const RenaMobile = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();
  const refImg1 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    const handleScroll = () => {
      // For the first section
      if (refImg1.current) {
        const rectImg1 = refImg1.current.getBoundingClientRect();
        if (rectImg1.top < window.innerHeight && rectImg1.bottom > 0) {
          imgApi1.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 200,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1400,
            },
          });
          textApi1.start({
            opacity: 1,
            delay: 200,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1, imgApi1]);

  return (
    <div className="home_ren">
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="home_ren_text"
      >
        <animated.div
          ref={refText1}
          style={{
            ...textSprings1,
          }}
          className="home_ren_text_b"
        ></animated.div>
        <text className="text_text_m">
          {i18n({ index: "home_1", language })}
        </text>
        <text className="text_text_wm">
          {i18n({ index: "home_2", language })}
        </text>
      </animated.div>
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="home_ren_text_b"
      >
        <text className="text_text_cm">
          {i18n({ index: "home_3", language })}
        </text>
      </animated.div>
      <animated.img
        ref={refImg1}
        src={dulce}
        className="home_ren_pic"
        alt="1"
        style={imgSprings1}
      />
    </div>
  );
};

export const HistoryMobile = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();
  const refImg1 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    const handleScroll = () => {
      if (refImg1.current) {
        const rectImg1 = refImg1.current.getBoundingClientRect();
        if (rectImg1.top < window.innerHeight && rectImg1.bottom > 0) {
          imgApi1.start({
            opacity: 1,
            transform: "translateX(0px)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });

          if (refText1.current) {
            const rectText1 = refText1.current.getBoundingClientRect();
            if (rectText1.top < window.innerHeight && rectText1.bottom > 0) {
              textApi1.start({
                opacity: 1,
                transform: "translateY(0)",
                delay: 400,
                config: {
                  tension: 170,
                  friction: 26,
                  precision: 0.01,
                  duration: 2000,
                },
              });
            }
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1, imgApi1]);

  return (
    <div className="home_v2_mobile">
      <animated.img
        ref={refImg1}
        src={tahini3}
        className="home_v2_pic_moby"
        alt="1"
        style={imgSprings1}
      />
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="history_mobile"
      >
        <text className="history_title">
          {i18n({ index: "history", language })}
        </text>
        <text className="history_text_mobile">
          {i18n({ index: "history_1", language })}
        </text>
        <text className="history_text_mobile">
          {i18n({ index: "history_2", language })}
        </text>
        <text className="history_text_mobile">
          {i18n({ index: "history_3", language })}
        </text>
        <text className="history_text_mobile">
          {i18n({ index: "history_4", language })}
        </text>
        <text className="history_text_mobile">
          {i18n({ index: "history_5", language })}
        </text>
      </animated.div>
    </div>
  );
};

export const HomeMobile1 = () => {
  const { ref, isVisible } = useOnScreen({ threshold: 1 });
  const styled = {
    width: "100%",
    height: 500,
  };

  return isVisible ? (
    <AsyncImage
      style={styled}
      src={fortext11}
      Transition={Fade}
      ref={ref}
      error={<div style={{ background: "#eee", height: 500, width: 500 }} />}
    />
  ) : (
    <div ref={ref} className={"home_desc_pic_m"}></div>
  );
};
