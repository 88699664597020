import { useEffect, useRef, useState } from "react";

const useOnScreen = (options) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); // Stop observing after it becomes visible
      }
    }, options);

    const reff = ref.current;
    if (reff) {
      observer.observe(reff);
    }

    return () => {
      if (reff) {
        observer.unobserve(reff);
      }
    };
  }, [options]);

  return { ref, isVisible };
};

export default useOnScreen;
