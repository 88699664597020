import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import all from "./../pics/120_crop.jpg";
import all_m from "./../pics/120_morecrop.jpg";
import { useUserSettings } from "../user";
import { i18n } from "../i18n";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { AsyncImage } from "loadable-image";
import { Slide } from "transitions-kit";

export const Contact = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const styled = { width: "79%", height: "100vh" };
  const styledd = { width: "100%", height: 330 };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <div className="contact_page_m">
        <AsyncImage
          style={styledd}
          src={all_m}
          Transition={(props) => <Slide direction="down" {...props} />}
          error={
            <div style={{ background: "#eee", height: 500, width: 500 }} />
          }
        />
        <div className="contact_text_m">
          <text className="text_contact_t">
            {i18n({ index: "contact_q", language })}
          </text>
          <div className="contact_row">
            <PlaceOutlinedIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 50,
              }}
            />
            <text className="text_contact_2">Punta Ballena, Uruguay</text>
          </div>
          <div className="contact_row">
            <WhatsAppIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 50,
              }}
            />
            <text className="text_contact_2">(+598) 97 581 354</text>
          </div>
          <div className="contact_row">
            <EmailOutlinedIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 50,
              }}
            />
            <text className="text_contact_2">halah.sales@gmail.com</text>
          </div>
          <div className="contact_row">
            <InstagramIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 50,
              }}
            />
            <text className="text_contact_2">@halah.uy</text>
          </div>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="contact_page" id="contact">
        <div className="contact_text">
          <text className="text_contact_t">
            {i18n({ index: "contact_q", language })}
          </text>
          <div className="contact_row">
            <PlaceOutlinedIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 25,
              }}
            />
            <text className="text_contact_2">Punta Ballena, Uruguay</text>
          </div>
          <div className="contact_row">
            <WhatsAppIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 25,
              }}
            />
            <text className="text_contact_2">(+598) 97 581 354</text>
          </div>
          <div className="contact_row">
            <EmailOutlinedIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 25,
              }}
            />
            <text className="text_contact_2">halah.sales@gmail.com</text>
          </div>
          <div className="contact_row">
            <InstagramIcon
              style={{
                color: "#FF7F50",
                fontSize: "xx-large",
                position: "absolute",
                left: 25,
              }}
            />
            <text className="text_contact_2">@halah.uy</text>
          </div>
        </div>
        <AsyncImage
          style={styled}
          src={all}
          Transition={(props) => <Slide direction="down" {...props} />}
          error={
            <div style={{ background: "#eee", height: 500, width: 500 }} />
          }
        />
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
