import "./../Style.css";
import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import fortext1 from "./../pics/zoomedin_hehe.jpg";
import fortext2 from "./../pics/Halah0208_web.jpg";
import fortext3 from "./../pics/Halah0404_web.jpg";
import tahini3 from "./../pics/home3.jpg";
import tahini1 from "./../pics/home1.jpg";
import tahini2 from "./../pics/home2.jpg";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { HashLink } from "react-router-hash-link";
import styles from "./styles.module.css";
import useMeasure from "react-use-measure";
// import EastSharpIcon from "@mui/icons-material/EastSharp";

export const MyComponent = () => {
  const [settings] = useUserSettings();
  const { language } = settings;
  const [open, toggle] = useState(false);

  const [ref, { width }] = useMeasure();
  const props = useSpring({ width: open ? width - 2 : 0 });

  const refText1 = useRef();
  const refImg1 = useRef();
  const refText2 = useRef();
  const refImg2 = useRef();
  const refText3 = useRef();
  const refImg3 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings2, textApi2] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings2, imgApi2] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings3, textApi3] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings3, imgApi3] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    const handleScroll = () => {
      // For the first section
      if (refText1.current) {
        const rectText1 = refText1.current.getBoundingClientRect();
        if (rectText1.top < window.innerHeight && rectText1.bottom > 0) {
          textApi1.start({
            opacity: 1,
            delay: 200,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 800,
            },
          });
          imgApi1.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 1000,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      // For the second section
      if (refText2.current) {
        const rectText2 = refText2.current.getBoundingClientRect();
        if (rectText2.top < window.innerHeight && rectText2.bottom > 0) {
          textApi2.start({
            opacity: 1,
            delay: 200,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 800,
            },
          });
          imgApi2.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 1000,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      // For the third section
      if (refText3.current) {
        const rectText3 = refText3.current.getBoundingClientRect();
        if (rectText3.top < window.innerHeight && rectText3.bottom > 0) {
          textApi3.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 200,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 800,
            },
          });
          imgApi3.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 1000,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1, imgApi1, textApi2, imgApi2, textApi3, imgApi3]);

  return (
    <div className="home_v2_lower">
      <div className="home_fullbox">
        <animated.div
          ref={refText1}
          style={{
            ...textSprings1,
          }}
          className="home_box"
        >
          <text className="box_t">{i18n({ index: "home_q1", language })}</text>
          <text className="box_st">
            {i18n({ index: "home_desc_1", language })}
          </text>
        </animated.div>
        <animated.img
          ref={refImg1}
          src={fortext1}
          className="home_desc_pic"
          alt="1"
          style={imgSprings1}
        />
      </div>
      <div className="home_fullbox">
        <animated.img
          ref={refImg2}
          src={fortext2}
          className="home_desc_pic"
          alt="2"
          style={imgSprings2}
        />
        <animated.div
          ref={refText2}
          style={{
            ...textSprings2,
          }}
          className="home_box"
        >
          <text className="box_t">{i18n({ index: "home_q2", language })}</text>
          <text className="box_st">
            {i18n({ index: "home_desc_2", language })}
          </text>
          <HashLink
            ref={ref}
            className={styles.rec_button}
            onMouseEnter={() => toggle(!open)}
            onMouseLeave={() => toggle(!open)}
            smooth
            to={"/recipes#noodles"}
          >
            <animated.div className={styles.rec_fill} style={props} />
            <text className={styles.t}>
              {i18n({ index: "see_recs", language })}
            </text>
            {/* <EastSharpIcon style={{ color: "#fff", marginLeft: 20 }} /> */}
          </HashLink>
        </animated.div>
      </div>
      <div className="home_fullbox">
        <animated.div
          ref={refText3}
          style={{
            ...textSprings3,
          }}
          className="home_box"
        >
          <text className="box_t">HALAH</text>
          <text className="box_st">
            {i18n({ index: "home_desc_3", language })}
          </text>
        </animated.div>
        <animated.img
          ref={refImg3}
          src={fortext3}
          className="home_desc_pic"
          alt="3"
          style={imgSprings3}
        />
      </div>
    </div>
  );
};

export const RenaDesktop = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();
  const refImg1 = useRef();
  const refText2 = useRef();
  const refImg2 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings2, textApi2] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings2, imgApi2] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    const handleScroll = () => {
      if (refImg1.current) {
        const rectImg1 = refImg1.current.getBoundingClientRect();
        if (rectImg1.top < window.innerHeight && rectImg1.bottom > 0) {
          imgApi1.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
          textApi1.start({
            opacity: 1,
            delay: 1400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
          imgApi2.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 2400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
          textApi2.start({
            opacity: 1,
            delay: 3400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1, imgApi1, textApi2, imgApi2]);

  return (
    <div className="home_v2">
      <animated.img
        ref={refImg1}
        src={tahini1}
        className="home_v2_pic"
        alt="1"
        style={imgSprings1}
      />
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="home_v2_text"
      >
        <text className="text_text">{i18n({ index: "home_1", language })}</text>
        <text className="text_text_w">
          {i18n({ index: "home_2", language })}
        </text>
        <text className="text_text_c">
          {i18n({ index: "home_3", language })}
        </text>
      </animated.div>

      <animated.img
        ref={refImg2}
        src={tahini2}
        className="home_v2_pic"
        alt="1"
        style={imgSprings2}
      />
      <animated.div
        ref={refText2}
        style={{
          ...textSprings2,
        }}
        className="home_v2_text"
      >
        <text className="text_text_wb">
          {i18n({ index: "home_4", language })}
        </text>
        <text className="text_text_b">
          {i18n({ index: "home_5", language })}
        </text>
      </animated.div>
    </div>
  );
};

export const HistoryDesktop = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();
  const refImg1 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  useEffect(() => {
    const handleScroll = () => {
      if (refImg1.current) {
        const rectImg1 = refImg1.current.getBoundingClientRect();
        if (rectImg1.top < window.innerHeight && rectImg1.bottom > 0) {
          imgApi1.start({
            opacity: 1,
            transform: "translateX(0px)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
          textApi1.start({
            opacity: 1,
            delay: 1400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1, imgApi1]);

  return (
    <div className="home_v2_bottom">
      <animated.img
        ref={refImg1}
        src={tahini3}
        className="home_v2_pic_bottom"
        alt="1"
        style={imgSprings1}
      />
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="home_v2_text_bottom"
      >
        <text className="history_title">
          {i18n({ index: "history", language })}
        </text>
        <text className="history_text">
          {i18n({ index: "history_1", language })}
        </text>
        <text className="history_text">
          {i18n({ index: "history_2", language })}
        </text>
        <text className="history_text">
          {i18n({ index: "history_3", language })}
        </text>
        <text className="history_text">
          {i18n({ index: "history_4", language })}
        </text>
        <text className="history_text">
          {i18n({ index: "history_5", language })}
        </text>
      </animated.div>
    </div>
  );
};

export const RenacidosDesktop = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();
  const refImg1 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    const handleScroll = () => {
      // For the first section
      if (refText1.current) {
        const rectText1 = refText1.current.getBoundingClientRect();
        if (rectText1.top < window.innerHeight && rectText1.bottom > 0) {
          textApi1.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg1.current) {
        const rectImg1 = refImg1.current.getBoundingClientRect();
        if (rectImg1.top < window.innerHeight && rectImg1.bottom > 0) {
          imgApi1.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [textApi1, imgApi1]);

  return (
    <div className="home_ren">
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="home_ren_text"
      >
        <text className="text_text_m">
          {i18n({ index: "home_1", language })}
        </text>
        <text className="text_text_wm">
          {i18n({ index: "home_2", language })}
        </text>
        <text className="text_text_cm">
          {i18n({ index: "home_3", language })}
        </text>
      </animated.div>
      <animated.img
        ref={refImg1}
        src={tahini3}
        className="home_ren_pic"
        alt="1"
        style={imgSprings1}
      />
    </div>
  );
};
