import React from "react";
import { isMobile } from "react-device-detect";
import fideos from "./../pics/fideos.jpg";
import salad from "./../pics/salad.jpg";
import toast from "./../pics/toast.jpg";
import cookies from "./../pics/cookies.jpg";
import { useUserSettings } from "../user";
import { i18n } from "../i18n";
import { AsyncImage } from "loadable-image";
import { Fade } from "transitions-kit";

export const Recetas = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const styledd = {
    width: "100%",
    height: "66vh",
    // marginTop: 100,
    // position: "absolute",
    // zIndex: 300,
  };

  const styled = {
    width: "100%",
    height: "100vh",
  };

  const MobileView = () => {
    return (
      <div className="rec_page_m" id="">
        <div className="recipe_container_m" id="noodles">
          <div className="rec_img_div_m">
            {/* <img className="rec_img" src={fideos} alt=""></img> */}
            <AsyncImage
              style={styledd}
              src={fideos}
              Transition={Fade}
              error={
                <div style={{ background: "#eee", height: 500, width: 500 }} />
              }
            />
            <text className="rec_img_title_m">
              {i18n({ index: "noodles", language })}
            </text>
          </div>
          <text className="rec_title_m">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <text>{i18n({ index: "portions", language })}</text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "base", language })}
              </text>
              <text className="rec_t3">
                <br /> {i18n({ index: "n_base_1", language })}
                <br /> {i18n({ index: "n_base_2", language })}
              </text>
              <br />{" "}
              <text className="rec_t21">
                {i18n({ index: "sauce", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "n_sauce_1", language })}
                <br />
                {i18n({ index: "n_sauce_2", language })}
                <br />
                {i18n({ index: "n_sauce_3", language })}
                <br />
                {i18n({ index: "n_sauce_4", language })}
                <br />
                {i18n({ index: "n_sauce_5", language })}
                <br />
                {i18n({ index: "n_sauce_6", language })}
              </text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "extra", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "n_extra_1", language })}
                <br />
                {i18n({ index: "n_extra_2", language })}
              </text>
            </div>
          </text>

          <text className="rec_title_m2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "n_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "n_step_2", language })}</text>
              <br /> <text>{i18n({ index: "n_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "n_step_4", language })}</text>
              <br />
              <text> {i18n({ index: "n_step_5", language })}</text>
            </div>
          </text>
        </div>

        <div className="recipe_container_m" id="toast">
          <div className="rec_img_div_m">
            {/* <img className="rec_img" src={toast} alt=""></img> */}
            <AsyncImage
              style={styledd}
              src={toast}
              Transition={Fade}
              error={
                <div style={{ background: "#eee", height: 500, width: 500 }} />
              }
            />
            <text className="rec_img_title_m">
              {i18n({ index: "toast", language })}
            </text>
          </div>
          <text className="rec_title_m">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <br />
              <text className="rec_t2">
                {i18n({ index: "essential", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "t_base_1", language })}
                <br />
                {i18n({ index: "t_base_2", language })}
                <br />
                {i18n({ index: "t_base_3", language })}
              </text>
              <br />{" "}
              <text className="rec_t21">
                {i18n({ index: "optional", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "t_extra_1", language })}
                <br />
                {i18n({ index: "t_extra_2", language })}
                <br />
                {i18n({ index: "t_extra_3", language })}
              </text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "note", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "t_comment", language })}
              </text>
            </div>
          </text>

          <text className="rec_title_m2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "t_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "t_step_2", language })}</text>
              <br />
              <text>{i18n({ index: "t_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "t_step_4", language })}</text>
            </div>
          </text>
        </div>

        <div className="recipe_container_m" id="salad">
          <div className="rec_img_div_m">
            <AsyncImage
              style={styledd}
              src={salad}
              Transition={Fade}
              error={
                <div style={{ background: "#eee", height: 500, width: 500 }} />
              }
            />
            <text className="rec_img_title_m">
              {i18n({ index: "salad", language })}
            </text>
          </div>
          <text className="rec_title_m">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <text>{i18n({ index: "portions_1", language })}</text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "base", language })}
              </text>
              <text className="rec_t3">
                <br /> {i18n({ index: "s_base_1", language })}
                <br /> {i18n({ index: "s_base_2", language })}
                <br /> {i18n({ index: "s_base_3", language })}
                <br /> {i18n({ index: "s_base_4", language })}
                <br /> {i18n({ index: "s_base_5", language })}
              </text>
              <br />
              <text className="rec_t21">
                {i18n({ index: "dressing", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "s_sauce_1", language })}
                <br />
                {i18n({ index: "s_sauce_2", language })}
              </text>
              <br />{" "}
              <text className="rec_t2">
                {i18n({ index: "extra", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "s_extra_1", language })}
                <br />
                {i18n({ index: "s_extra_2", language })}
                <br />
                {i18n({ index: "s_extra_3", language })}
              </text>
              <br />
            </div>
          </text>

          <text className="rec_title_m2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "s_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "s_step_2", language })}</text>
              <br />
              <text>{i18n({ index: "s_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "s_step_4", language })}</text>
            </div>
          </text>
        </div>

        <div className="recipe_container_m" id="cookies">
          <div className="rec_img_div_m">
            <AsyncImage
              style={styledd}
              src={cookies}
              Transition={Fade}
              error={
                <div style={{ background: "#eee", height: 500, width: 500 }} />
              }
            />
            <text className="rec_img_title_m">
              {i18n({ index: "cookies", language })}
            </text>
          </div>
          <text className="rec_title_m">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <text>{i18n({ index: "portions_2", language })}</text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "masa", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "c_base_1", language })}
                <br />
                {i18n({ index: "c_base_2", language })}
                <br />
                {i18n({ index: "c_base_3", language })}
                <br />
                {i18n({ index: "c_base_4", language })}
              </text>
              <br />
              <text className="rec_t21">
                {i18n({ index: "toppings", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "c_extra_1", language })}
              </text>
            </div>
          </text>

          <text className="rec_title_m2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "c_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_2", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_4", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_5", language })}</text>
            </div>
          </text>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="rec_page" id="">
        <div className="recipe_container" id="noodles">
          <text className="rec_title">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <text>{i18n({ index: "portions", language })}</text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "base", language })}
              </text>
              <text className="rec_t3">
                <br /> {i18n({ index: "n_base_1", language })}
                <br /> {i18n({ index: "n_base_2", language })}
              </text>
              <br />
              <text className="rec_t21">
                {i18n({ index: "sauce", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "n_sauce_1", language })}
                <br />
                {i18n({ index: "n_sauce_2", language })}
                <br />
                {i18n({ index: "n_sauce_3", language })}
                <br />
                {i18n({ index: "n_sauce_4", language })}
                <br />
                {i18n({ index: "n_sauce_5", language })}
                <br />
                {i18n({ index: "n_sauce_6", language })}
              </text>
              <br />{" "}
              <text className="rec_t2">
                {i18n({ index: "extra", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "n_extra_1", language })}
                <br />
                {i18n({ index: "n_extra_2", language })}
              </text>
            </div>
          </text>

          <text className="rec_title_2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "n_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "n_step_2", language })}</text>
              <br /> <text>{i18n({ index: "n_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "n_step_4", language })}</text>
              <br />
              <text> {i18n({ index: "n_step_5", language })}</text>
            </div>
          </text>
          <div className="rec_img_div">
            {/* <img className="rec_img" src={fideos} alt=""></img> */}
            <AsyncImage
              style={styled}
              src={fideos}
              Transition={Fade}
              error={
                <div style={{ background: "#eee", height: 500, width: 500 }} />
              }
            />
            <text className="rec_img_title">
              {i18n({ index: "noodles", language })}
            </text>
          </div>
        </div>

        <div className="recipe_container" id="toast">
          <div className="rec_img_div">
            <img className="rec_img" src={toast} alt=""></img>
            <text className="rec_img_title">
              {i18n({ index: "toast", language })}
            </text>
          </div>

          <text className="rec_title_2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "t_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "t_step_2", language })}</text>
              <br />
              <text>{i18n({ index: "t_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "t_step_4", language })}</text>
            </div>
          </text>

          <text className="rec_title">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <br />
              <text className="rec_t2">
                {i18n({ index: "essential", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "t_base_1", language })}
                <br />
                {i18n({ index: "t_base_2", language })}
                <br />
                {i18n({ index: "t_base_3", language })}
              </text>
              <br />{" "}
              <text className="rec_t21">
                {i18n({ index: "optional", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "t_extra_1", language })}
                <br />
                {i18n({ index: "t_extra_2", language })}
                <br />
                {i18n({ index: "t_extra_3", language })}
              </text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "note", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "t_comment", language })}
              </text>
            </div>
          </text>
        </div>

        <div className="recipe_container" id="salad">
          <text className="rec_title">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <text>{i18n({ index: "portions_1", language })}</text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "base", language })}
              </text>
              <text className="rec_t3">
                <br /> {i18n({ index: "s_base_1", language })}
                <br /> {i18n({ index: "s_base_2", language })}
                <br /> {i18n({ index: "s_base_3", language })}
                <br /> {i18n({ index: "s_base_4", language })}
                <br /> {i18n({ index: "s_base_5", language })}
              </text>
              <br />
              <text className="rec_t21">
                {i18n({ index: "dressing", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "s_sauce_1", language })}
                <br />
                {i18n({ index: "s_sauce_2", language })}
              </text>
              <br />{" "}
              <text className="rec_t2">
                {i18n({ index: "extra", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "s_extra_1", language })}
                <br />
                {i18n({ index: "s_extra_2", language })}
                <br />
                {i18n({ index: "s_extra_3", language })}
              </text>
              <br />
            </div>
          </text>

          <text className="rec_title_2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "s_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "s_step_2", language })}</text>
              <br />
              <text>{i18n({ index: "s_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "s_step_4", language })}</text>
            </div>
          </text>
          <div className="rec_img_div">
            <img className="rec_img" src={salad} alt=""></img>
            <text className="rec_img_title">
              {i18n({ index: "salad", language })}
            </text>
          </div>
        </div>

        <div className="recipe_container" id="cookies">
          <div className="rec_img_div">
            <img className="rec_img" src={cookies} alt=""></img>
            <text className="rec_img_title">
              {i18n({ index: "cookies", language })}
            </text>
          </div>

          <text className="rec_title_2">
            <div className="rec_inner">
              <text className="rec_t4">
                {i18n({ index: "prep", language })}
              </text>
              <br />
              <text>{i18n({ index: "c_step_1", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_2", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_3", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_4", language })}</text>
              <br />
              <text>{i18n({ index: "c_step_5", language })}</text>
            </div>
          </text>

          <text className="rec_title">
            <div className="rec_inner">
              <text className="rec_t1">
                {i18n({ index: "ingredients", language })}
              </text>
              <br />
              <text>{i18n({ index: "portions_2", language })}</text>
              <br />
              <text className="rec_t2">
                {i18n({ index: "masa", language })}
              </text>
              <text className="rec_t3">
                <br />
                {i18n({ index: "c_base_1", language })}
                <br />
                {i18n({ index: "c_base_2", language })}
                <br />
                {i18n({ index: "c_base_3", language })}
                <br />
                {i18n({ index: "c_base_4", language })}
              </text>
              <br />
              <text className="rec_t21">
                {i18n({ index: "toppings", language })}
              </text>
              <text>
                <br />
                {i18n({ index: "c_extra_1", language })}
              </text>
            </div>
          </text>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
