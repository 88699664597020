import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import logo from "./../pics/logo.png";
import en from "./../pics/en.png";
import es from "./../pics/es.png";
import fr from "./../pics/fr.webp";
import { useUserSettings } from "../user";
import { i18n } from "../i18n";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { HashLink } from "react-router-hash-link";
import { useSpring, animated } from "@react-spring/web";
import useMeasure from "react-use-measure";
import styles from "./styles.module.css";

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRecipesOpen, setIsRecipesOpen] = useState(false);
  const [settings, setSettings] = useUserSettings();
  const { language } = settings;
  const [blockAnimation, setBlockAnimation] = useState(false);
  const [_open, _toggle] = useState(false);
  const [ref, { width }] = useMeasure();
  const props = useSpring({
    width: _open ? width : 0,
    opacity: _open ? 1 : 0,
  });

  const setEnglish = () => {
    setSettings({ ...settings, language: "en" });
  };

  const setSpanish = () => {
    setSettings({ ...settings, language: "es" });
  };

  const setFrench = () => {
    setSettings({ ...settings, language: "fr" });
  };

  const toggle = () => {
    setBlockAnimation(false);
    setIsRecipesOpen(false);
    setIsOpen(!isOpen);
  };

  const toggleRecipes = () => {
    setBlockAnimation(true);
    setIsRecipesOpen(!isRecipesOpen);
  };

  const Drawer = ({ show, onClose, isOpen }) => {
    const drawerRef = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
          onClose(); // Call onClose if clicked outside
        }
      };

      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen, onClose]);

    const props = useSpring({
      // width: show ? width : 0,
      from: { x: show ? -100 : 0 },
      to: { x: 0 },
    });

    return (
      <animated.div ref={drawerRef} className={styles.navbaropen} style={props}>
        <div className="options">
          <div className="option">
            <button
              className="lang_m"
              onClick={() => {
                setEnglish();
                toggle();
              }}
            >
              <img src={en} class="lang_img_m" alt="Logo" />
            </button>
            <button
              className="lang_m"
              onClick={() => {
                setSpanish();
                toggle();
              }}
            >
              <img src={es} class="lang_img_m" alt="Logo" />
            </button>
            <button className="lang_m">
              <img
                src={fr}
                class="lang_img_m"
                alt="Logo"
                onClick={() => {
                  setFrench();
                  toggle();
                }}
              />
            </button>
          </div>
          <Link className="option" to={"/"} onClick={toggle}>
            <text> {i18n({ index: "home", language })}</text>
          </Link>
          <Link className="option" to={"/products"} onClick={toggle}>
            <text> {i18n({ index: "products", language })}</text>
          </Link>
          <div className="option_rec">
            <HashLink
              className="option"
              smooth
              to={"/recipes#noodles"}
              onClick={toggle}
            >
              <text> {i18n({ index: "recipes", language })}</text>
            </HashLink>
            <button onClick={toggleRecipes} className="rec_icon">
              {isRecipesOpen ? (
                <KeyboardArrowUpIcon style={{ color: "#fff" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: "#fff" }} />
              )}
            </button>
          </div>
          {isRecipesOpen && (
            <div className="sub_rec">
              <HashLink
                className="suboption"
                smooth
                to={"/recipes#noodles"}
                onClick={toggle}
              >
                <text>{i18n({ index: "n_noodles", language })}</text>
              </HashLink>
              <HashLink
                className="suboption"
                smooth
                to={"/recipes#toast"}
                onClick={toggle}
              >
                <text>{i18n({ index: "n_toast", language })}</text>
              </HashLink>
              <HashLink
                className="suboption"
                smooth
                to={"/recipes#salad"}
                onClick={toggle}
              >
                <text>{i18n({ index: "n_salad", language })}</text>
              </HashLink>
              <HashLink
                className="suboption"
                smooth
                to={"/recipes#cookies"}
                onClick={toggle}
              >
                <text>{i18n({ index: "n_cookies", language })}</text>
              </HashLink>
            </div>
          )}
          <Link className="option" to={"/where"} onClick={toggle}>
            <text>{i18n({ index: "find_us", language })}</text>
          </Link>
          <Link className="option" to={"/contact"} onClick={toggle}>
            <text>{i18n({ index: "contact", language })}</text>
          </Link>
        </div>
      </animated.div>
    );
  };

  const MobileView = () => {
    return (
      <div className="navbarclosedmobile">
        <Link to={"/"}>
          <img src={logo} className="nav_img_mobile" alt="Logo" />
        </Link>
        <button className="icon">
          {isOpen ? (
            <CloseIcon
              onClick={() => setIsOpen(false)}
              style={{ color: "#fff" }}
            />
          ) : (
            <MenuIcon
              onClick={() => setIsOpen(true)}
              style={{ color: "#fff" }}
            />
          )}
        </button>
        {isOpen && (
          <Drawer
            show={blockAnimation === false}
            onClose={toggle}
            isOpen={isOpen}
          />
        )}
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="navbarclosed">
        <Link class="nav_imgcontainer" to={"/"}>
          <img src={logo} class="nav_img" alt="Logo" />
        </Link>
        <div className="nav_right">
          <button className="home_button">
            <Link className="nav_option" to={"/products"}>
              <text> {i18n({ index: "products", language })}</text>
            </Link>
          </button>
          <button
            className="home_button"
            onMouseOver={() => {
              _toggle(true);
            }}
            onMouseOut={() => {
              _toggle(false);
            }}
          >
            <HashLink className="nav_option" smooth to={"/recipes#noodles"}>
              <text>{i18n({ index: "recipes", language })}</text>
            </HashLink>
            <div ref={ref}>
              <animated.div className={styles.subheader} style={props}>
                {_open ? (
                  <>
                    <HashLink
                      className={styles.subheader_text}
                      smooth
                      to={"/recipes#noodles"}
                    >
                      <text className="rec_option">
                        {i18n({ index: "n_noodles", language })}
                      </text>
                    </HashLink>
                    <HashLink
                      className={styles.subheader_text}
                      smooth
                      to={"/recipes#toast"}
                    >
                      <text className="rec_option">
                        {i18n({ index: "n_toast", language })}
                      </text>
                    </HashLink>
                    <HashLink
                      className={styles.subheader_text}
                      smooth
                      to={"/recipes#salad"}
                    >
                      <text className="rec_option">
                        {i18n({ index: "n_salad", language })}
                      </text>
                    </HashLink>
                    <HashLink
                      className={styles.subheader_text}
                      smooth
                      to={"/recipes#cookies"}
                      // onClick={_toggle}
                    >
                      <text className="rec_option">
                        {i18n({ index: "n_cookies", language })}
                      </text>
                    </HashLink>
                  </>
                ) : (
                  <></>
                )}
              </animated.div>
            </div>
            {/* {_open && (
              // <div
              //   className="subheader"
              //   onMouseOver={handleMouseOver}
              //   onMouseOut={handleMouseOut}
              // >
              //   <HashLink
              //     onClick={handleMouseOut}
              //     className="subheader_text"
              //     smooth
              //     to={"/recipes#rec_1"}
              //   >
              //     <text className="rec_option">Coming</text>
              //   </HashLink>
              //   <HashLink
              //     onClick={handleMouseOut}
              //     className="subheader_text"
              //     smooth
              //     to={"/recipes#rec_2"}
              //   >
              //     <text className="rec_option">Soon</text>
              //   </HashLink>
              // </div>
              <RecipesDrawer _open={_open} />
            )} */}
          </button>
        </div>
        <div className="nav_right">
          <button className="home_button">
            <Link className="nav_option" to={"/where"}>
              <text> {i18n({ index: "find_us", language })}</text>
            </Link>
          </button>
          <button className="home_button">
            <Link className="nav_option" to={"/contact"}>
              <text> {i18n({ index: "contact", language })}</text>
            </Link>
          </button>
          <div className="langs">
            <button className="lang_m" onClick={() => setEnglish()}>
              <img src={en} class="lang_img_m" alt="Logo" />
            </button>
            <button className="lang_m">
              <img
                src={es}
                class="lang_img_m"
                alt="Logo"
                onClick={() => setSpanish()}
              />
            </button>
            <button className="lang_m">
              <img
                src={fr}
                class="lang_img_m"
                alt="Logo"
                onClick={() => setFrench()}
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return isMobile ? <MobileView /> : <DesktopView />;
};
