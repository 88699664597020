import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./Components/Home";
import { Navigation } from "./Components/Navigation";
import { Products } from "./Components/Products";
import { Footer } from "./Components/Footer";
import { UserProvider } from "./user";
import { FindUs } from "./Components/FindUs";
import { Contact } from "./Components/Contact";
import { Recetas } from "./Components/Recetas";

function App() {
  const settings = { language: "es" };
  return (
    <UserProvider settings={settings}>
      <Router>
        <Navigation />
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/recipes" element={<Recetas />} />
            <Route path="/where" element={<FindUs />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </UserProvider>
  );
}

export default App;
