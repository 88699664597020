import useOnScreen from "../refs";
import "animate.css";
import "./../Style.css";
import classic from "./../pics/Halah0055_web.jpg";
import plateado from "./../pics/Halah0067_web.jpg";
import paprika from "./../pics/Halah0064_web.jpg";
import pesto from "./../pics/Halah0069_web.jpg";
import cacao from "./../pics/Halah0133_web.jpg";
import sweet from "./../pics/Halah0136_web.jpg";
import sesame from "./../pics/Halah0145_web.jpg";
import big from "./../pics/Halah0081_web.jpg";
import { useState } from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import React, { useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";

export const ProductsMobile = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const refText1 = useRef();
  const refImg1 = useRef();
  const refImg2 = useRef();
  const refText2 = useRef();
  const refImg3 = useRef();
  const refImg4 = useRef();
  const refText3 = useRef();
  const refImg5 = useRef();
  const refImg6 = useRef();
  const refText4 = useRef();
  const refImg7 = useRef();
  const refImg8 = useRef();

  const [textSprings1, textApi1] = useSpring(() => ({
    opacity: 0,
    // transform: "translateX(100px)",
  }));

  const [imgSprings1, imgApi1] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings2, imgApi2] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings2, textApi2] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings3, imgApi3] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings4, imgApi4] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings3, textApi3] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings5, imgApi5] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings6, imgApi6] = useSpring(() => ({
    opacity: 0,
  }));

  const [textSprings4, textApi4] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings7, imgApi7] = useSpring(() => ({
    opacity: 0,
  }));

  const [imgSprings8, imgApi8] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    const handleScroll = () => {
      // For the first section
      if (refText1.current) {
        const rectText1 = refText1.current.getBoundingClientRect();
        if (rectText1.top < window.innerHeight && rectText1.bottom > 0) {
          textApi1.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg1.current) {
        const rectImg1 = refImg1.current.getBoundingClientRect();
        if (rectImg1.top < window.innerHeight && rectImg1.bottom > 0) {
          imgApi1.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg2.current) {
        const rectImg2 = refImg2.current.getBoundingClientRect();
        if (rectImg2.top < window.innerHeight && rectImg2.bottom > 0) {
          imgApi2.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 2000,
            },
          });
        }
      }

      if (refText2.current) {
        const rectText2 = refText2.current.getBoundingClientRect();
        if (rectText2.top < window.innerHeight && rectText2.bottom > 0) {
          textApi2.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg3.current) {
        const rectImg3 = refImg3.current.getBoundingClientRect();
        if (rectImg3.top < window.innerHeight && rectImg3.bottom > 0) {
          imgApi3.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg4.current) {
        const rectImg4 = refImg4.current.getBoundingClientRect();
        if (rectImg4.top < window.innerHeight && rectImg4.bottom > 0) {
          imgApi4.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refText3.current) {
        const rectText3 = refText3.current.getBoundingClientRect();
        if (rectText3.top < window.innerHeight && rectText3.bottom > 0) {
          textApi3.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg5.current) {
        const rectImg5 = refImg5.current.getBoundingClientRect();
        if (rectImg5.top < window.innerHeight && rectImg5.bottom > 0) {
          imgApi5.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refImg6.current) {
        const rectImg6 = refImg6.current.getBoundingClientRect();
        if (rectImg6.top < window.innerHeight && rectImg6.bottom > 0) {
          imgApi6.start({
            opacity: 1,
            transform: "translateY(0)",
            delay: 400,
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }

      if (refText4.current) {
        const rectText4 = refText4.current.getBoundingClientRect();
        if (rectText4.top < window.innerHeight && rectText4.bottom > 0) {
          textApi4.start({
            opacity: 1,
            delay: 400,
            transform: "translateY(0)",
            config: {
              tension: 170,
              friction: 26,
              precision: 0.01,
              duration: 1000,
            },
          });
        }
      }
    };

    if (refImg7.current) {
      const rectImg7 = refImg7.current.getBoundingClientRect();
      if (rectImg7.top < window.innerHeight && rectImg7.bottom > 0) {
        imgApi7.start({
          opacity: 1,
          transform: "translateY(0)",
          delay: 400,
          config: {
            tension: 170,
            friction: 26,
            precision: 0.01,
            duration: 1000,
          },
        });
      }
    }

    if (refImg8.current) {
      const rectImg8 = refImg8.current.getBoundingClientRect();
      if (rectImg8.top < window.innerHeight && rectImg8.bottom > 0) {
        imgApi8.start({
          opacity: 1,
          transform: "translateY(0)",
          delay: 400,
          config: {
            tension: 170,
            friction: 26,
            precision: 0.01,
            duration: 1000,
          },
        });
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    textApi1,
    imgApi1,
    imgApi2,
    textApi2,
    imgApi3,
    imgApi4,
    textApi3,
    imgApi5,
    imgApi6,
    textApi4,
    imgApi7,
    imgApi8,
  ]);

  return (
    <div className="product_contained_m">
      <animated.div
        ref={refText1}
        style={{
          ...textSprings1,
        }}
        className="extraspace_m"
      >
        <text class="big_text_m">{i18n({ index: "classic", language })}</text>
      </animated.div>
      <animated.div ref={refImg1} alt="1" style={imgSprings1}>
        <Classic />
      </animated.div>
      <animated.div ref={refImg2} alt="1" style={imgSprings2}>
        <Plateado />
      </animated.div>

      <animated.div
        ref={refText2}
        style={{
          ...textSprings2,
        }}
        className="extra_space_m"
      >
        <text class="big_text_m">{i18n({ index: "flavor", language })}</text>
      </animated.div>
      <animated.div ref={refImg3} alt="1" style={imgSprings3}>
        <Paprika />
      </animated.div>
      <animated.div ref={refImg4} alt="1" style={imgSprings4}>
        <Pesto />
      </animated.div>
      <animated.div
        ref={refText3}
        style={{
          ...textSprings3,
        }}
        className="extra_space_m"
      >
        <text class="big_text_m">{i18n({ index: "sweet", language })}</text>
      </animated.div>
      <animated.div ref={refImg5} alt="1" style={imgSprings5}>
        <Cacao />
      </animated.div>
      <animated.div ref={refImg6} alt="1" style={imgSprings6}>
        <Sweet />
      </animated.div>
      <animated.div
        ref={refText4}
        style={{
          ...textSprings4,
        }}
        className="extraspace_m"
      >
        <text class="big_text_m">{i18n({ index: "variety", language })}</text>
      </animated.div>
      <animated.div ref={refImg7} alt="1" style={imgSprings7}>
        <Sesame />
      </animated.div>
      <animated.div ref={refImg8} alt="1" style={imgSprings8}>
        <Big />
      </animated.div>
    </div>
  );
};

export const Classic1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={classic} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "classic_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "classic_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "classic_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "classic_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={classic} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Plateado1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={plateado} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "silver_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "silver_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "silver_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "silver_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={plateado} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Paprika1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={paprika} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "paprika_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "paprika_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "paprika_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "paprika_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={paprika} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Pesto1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={pesto} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "pesto_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "pesto_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "pesto_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "pesto_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={pesto} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Cacao1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={cacao} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "cacao_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "cacao_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "cacao_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "cacao_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={cacao} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Sweet1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={sweet} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "sweet_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "sweet_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sweet_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sweet_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={sweet} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Sesame1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={sesame} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "sesame_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "sesame_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sesame_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sesame_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={sesame} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Big1 = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div className="product_contained_m">
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={big} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "big_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "big_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "big_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "big_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={big} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Classic = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInLeft" : ""
      }`}
    >
      <div className="extraspace_m">
        <text class="big_text_m">{i18n({ index: "classic", language })}</text>
      </div>
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={classic} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "classic_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "classic_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "classic_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "classic_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={classic} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Plateado = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInLeft" : ""
      }`}
    >
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={plateado} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "silver_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "silver_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "silver_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "silver_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={plateado} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Paprika = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInRight" : ""
      }`}
    >
      <div className="extraspace_m">
        <text class="big_text_m">{i18n({ index: "flavor", language })}</text>
      </div>
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={paprika} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "paprika_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "paprika_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "paprika_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "paprika_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={paprika} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Pesto = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInLeft" : ""
      }`}
    >
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={pesto} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "pesto_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "pesto_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "pesto_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "pesto_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={pesto} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Cacao = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInRight" : ""
      }`}
    >
      <div className="extraspace_m">
        <text class="big_text_m">{i18n({ index: "sweet", language })}</text>
      </div>
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={cacao} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "cacao_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "cacao_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "cacao_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "cacao_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={cacao} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Sweet = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInLeft" : ""
      }`}
    >
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={sweet} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "sweet_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "sweet_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sweet_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sweet_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={sweet} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Sesame = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInRight" : ""
      }`}
    >
      <div className="extraspace_m">
        <text class="big_text_m">{i18n({ index: "variety", language })}</text>
      </div>
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={sesame} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "sesame_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "sesame_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sesame_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "sesame_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={sesame} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};

export const Big = () => {
  const [description, setDescription] = useState(false);
  const [settings] = useUserSettings();
  const { language } = settings;
  const { ref, isVisible } = useOnScreen({ threshold: 0.1 });

  const toggleSwitch = () => {
    setDescription(!description);
  };

  return (
    <div
      ref={ref}
      className={`product_contained_m ${
        isVisible ? "animate__animated animate__fadeInLeft" : ""
      }`}
    >
      {description ? (
        <button className="product_m" onClick={toggleSwitch}>
          <img className="product_img_mobile" src={big} alt="" />
          <div class="content_m">
            <text className="arttitle_m">
              {i18n({ index: "big_title", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "big_ingredients", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "big_description", language })}
            </text>
            <text className="arttext_m">
              {i18n({ index: "nutritional_info", language })}
            </text>
            <text className="artsubtext_m">
              {i18n({ index: "big_nutrition", language })}
            </text>
          </div>
        </button>
      ) : (
        <button onClick={toggleSwitch} className="product_m">
          <img src={big} className="product_img_mobile" alt="" />
        </button>
      )}
    </div>
  );
};
